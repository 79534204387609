/*----------------------
    Rbt List Style  
------------------------*/
// Style 01

.rbt-list-style-1 {
  @extend %liststyle;
  li {
    display: flex;
    font-size: 18px;
    margin: 7px 0;
    line-height: 1.45;
    @media #{$sm-layout} {
      font-size: 16px;
    }
    i {
      padding-right: 10px;
      margin-top: 5px;
      color: var(--color-success);
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.color-white {
    li {
      color: var(--color-white);
    }
  }
}

// Style 02
.rbt-list-style-2 {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: -10px;
  margin-bottom: 40px;
  @media #{$large-mobile} {
    display: block;
    text-align: left;
  }
  li {
    margin: 10px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-align: center;
    display: flex;
    @media #{$large-mobile} {
      text-align: left;
    }
    i {
      width: 30px;
      height: 30px;
      background: var(--color-white);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      color: var(--color-primary);
      display: inline-block;
      line-height: 29px;
      margin-right: 10px;
      font-weight: 700;
      text-align: center;
    }
  }
}

.rbt-list-style-2-new {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: -10px;
  // margin-bottom: 40px;
  @media #{$large-mobile} {
    display: block;
    text-align: left;
  }
  li {
    margin: 10px;
    font-size: 14px;
    font-weight: 500;
    // letter-spacing: -0.50px;
    text-align: center;
    display: flex;
    @media #{$large-mobile} {
      text-align: left;
    }
    i {
      width: 30px;
      height: 30px;
      background: var(--color-white);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      color: var(--color-primary);
      display: inline-block;
      line-height: 29px;
      margin-right: 10px;
      font-weight: 700;
      text-align: center;
    }
  }
}

// Style 01
.rbt-list-style-3 {
  @extend %liststyle;
  li {
    display: flex;
    font-size: 16px;
    margin: 7px 0;
    line-height: 1.45;
    color: var(--color-body);
    i {
      padding-right: 10px;
      margin-top: 4px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.color-white {
    li {
      color: var(--color-white);
    }
  }
}

// Plan Offer List
.plan-offer-list {
  @extend %liststyle;
  margin: -10px;
  li {
    font-size: 16px;
    margin: 10px;
    i {
      width: 22px;
      height: 22px;
      // background: var(--color-success);
      margin: 0 auto;
      line-height: 22px;
      text-align: center;
      border-radius: 100%;
      color: var(--color-white);
      font-size: 14px;
      margin-right: 10px;
      display: inline-block;
    }
    &.off {
      opacity: 0.5;
      i {
        background: var(--color-danger);
      }
    }
  }
  &.rbt-list-primary-opacity {
    li {
      i {
        background: var(--primary-opacity) !important;
        color: var(--color-primary) !important;
      }
    }
  }
  &.rbt-list-white-opacity {
    li {
      i {
        background: var(--white-opacity) !important;
        color: var(--color-white) !important;
      }
    }
  }
}

/*-------------------------------
 Modern Course Features  
-------------------------------*/
.modern-course-features-box {
  background: var(--primary-opacity);
  border-radius: 20px;
  padding: 40px;
  @media #{$md-layout} {
    padding: 30px;
  }
  @media #{$sm-layout} {
    padding: 30px;
  }
  @media #{$large-mobile} {
    padding: 20px;
  }
  .inner {
    display: flex;
    align-items: center;
    @media #{$md-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: block;
    }
    .thumbnail {
      // flex-basis: 50%;
      img {
        border-radius: 10px;
        width: 100%;
      }
    }
    .content {
      flex-basis: 50%;
      padding-left: 30px;
      @media #{$md-layout} {
        padding-left: 0;
        padding-top: 30px;
      }
      @media #{$sm-layout} {
        padding-left: 0;
        padding-top: 30px;
      }
      .title {
        color: var(--color-white);
        font-size: 36px;
        // padding-right: 14%;
        // margin-bottom: 20px;
        // margin-top: -11px;
        @media #{$sm-layout} {
          font-size: 26px;
          padding-right: 0;
          margin-top: 0;
        }
      }
      p {
        color: var(--color-white-off);
        margin-bottom: 35px;
        @media #{$md-layout} {
          margin-bottom: 20px;
        }
        @media #{$sm-layout} {
          margin-bottom: 20px;
        }
      }
    }
    ul {
      li {
        color: var(--color-white-off);
        i {
          background: var(--primary-opacity);
          color: var(--color-primary);
        }
      }
    }
  }

  &.one-colume-grid {
    .inner {
      display: block;
      .content {
        padding-left: 0;
        padding-top: 34px;
      }
    }
  }
  &.grid-content-reverse {
    .inner {
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 100%;
      @media #{$sm-layout} {
        flex-direction: column;
      }
      .thumbnail {
        flex-basis: 100%;
        width: 100%;
        margin-top: 30px;
      }
      .content {
        flex-basis: 100%;
        padding-left: 0;
      }
      .rbt-image-gallery-wrapper {
        width: 100%;
        display: block;
      }
    }
  }
  &.grid-content-no-flex {
    .inner {
      display: block;
    }
  }
}

.modern-course-features-box-new {
  background: white;
  border-radius: 20px;
  padding: 40px;
  @media #{$md-layout} {
    padding: 30px;
  }
  @media #{$sm-layout} {
    padding: 30px;
  }
  @media #{$large-mobile} {
    padding: 20px;
  }
  .inner {
   
    .thumbnail {
      flex-basis: 50%;
      img {
        border-radius: 10px;
        width: 100%;
      }
    }
    .content {
      flex-basis: 50%;
      padding-left: 30px;
      @media #{$md-layout} {
        padding-left: 0;
        padding-top: 30px;
      }
      @media #{$sm-layout} {
        padding-left: 0;
        padding-top: 30px;
      }
      .title {
        color: black;
        font-size: 36px;
        margin-bottom: 30px;
        margin-top: 15px;
        @media #{$sm-layout} {
          font-size: 26px;
          padding-right: 0;
          margin-top: 0;
        }
      }
      p {
        color: black;
        /* margin-bottom: 35px;*/
      }
    }
    ul {
      li {
        color: var(--color-white-off);
        i {
          background: var(--primary-opacity);
          color: var(--color-primary);
        }
      }
    }
  }

  &.one-colume-grid {
    .inner {
      display: block;
      .content {
        padding-left: 0;
        padding-top: 34px;
      }
    }
  }
  &.grid-content-reverse {
    .inner {
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 100%;
      @media #{$sm-layout} {
        flex-direction: column;
      }
      .thumbnail {
        flex-basis: 100%;
        width: 100%;
        // margin-top: 30px;
      }
      .content {
        flex-basis: 100%;
        padding-left: 0;
      }
      .rbt-image-gallery-wrapper {
        width: 100%;
        display: block;
      }
    }
  }
  &.grid-content-no-flex {
    .inner {
      display: block;
    }
  }
}

.bg-color-white {
  .modern-course-features-box .inner .content .title,
  .course-feature-list li .feature-content .featute-title {
    color: var(--color-heading);
  }
  .modern-course-features-box .inner .content p,
  .course-feature-list li .feature-content .featute-title span {
    color: var(--color-body);
  }
  .modern-course-features-box {
    background: var(--secondary-opacity);
  }

  .modern-course-features-box .inner ul li {
    color: var(--color-body);
  }
}

/*---------------------------
    Course Feature List  
-----------------------------*/
.course-feature-list {
  @extend %liststyle;
  display: flex;
  flex-wrap: wrap;
  /* margin: -10px -20px;*/
  margin-bottom: 12px;
  @media #{$sm-layout} {
    margin: -10px -10px;
  }
  li {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin: 0;
    @media #{$sm-layout} {
      padding: 10px 10px;
    }
    .icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-size: 18px;
      margin-right: 12px;
      i {
        color: var(--color-white);
      }
    }
    .feature-content {
      .featute-title {
        color: var(--color-white);
        margin-bottom: 0;
        font-size: 20px;
        @media #{$sm-layout} {
          font-size: 16px;
        }
        span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          color: var(--color-white-off);
          @media #{$sm-layout} {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.plan-offer-list-wrapper {
  display: flex;
  @media #{$large-mobile} {
    display: block;
  }
  .plan-offer-list {
    & + .plan-offer-list {
      margin-left: 50px;
      @media #{$large-mobile} {
        margin-left: -10px;
        margin-top: 30px;
      }
    }
  }
}

.why-talentely-list-section {
    margin-top: 0px;
  @media #{$sm-layout} {
    margin-top: 30px !important;
  }
}
